import React, { useState, useEffect } from 'react';
import styles from './formStyling.module.css';
import TimePicker from './TimePicker';
import PackageList from './PackageList';
import axios from 'axios'
import Modal from './Modal';



function ServicePackages({ enabledPackages, onPackageClick, service, onBackClick, onNextClick, eventType, step, serviceId, onWeddingTypeChange, weddingType, onStartChange, onEndChange }) {



	const [isModalOpen, setModalOpen] = useState(false);
	const [currentVideo, setCurrentVideo] = useState(null);

	const handleImageClick = (event, videoUrl) => {
		event.stopPropagation(); // Prevent the li onClick from firing

		// Add /embed correctly to the YouTube video URL
		const embedUrl = videoUrl.replace("watch?v=", "embed/");

		setCurrentVideo(embedUrl);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		setCurrentVideo(null);
	};

	const [packagesData, setPackagesData] = useState([]);

	const isDJService = (serviceId == process.env.REACT_APP_DJ_SERVICE);


	useEffect(() => {

		const fetchPackages = async () => {
			const response = await axios.get(process.env.REACT_APP_API_BASE_URL + "packagesForService/" + serviceId);
			setPackagesData(response.data.packages.filter(item => item.eventtypes.map(type => type.id).includes(parseInt(eventType))));
		}

		fetchPackages();
	}, [serviceId, eventType])





	

	return (
		<div className={styles.wrap} >
			<h1>{service}  Step { step } - Choose Package</h1>
			<h3>Please choose a package that best suits your needs <span className={styles.onlyOne}>(choose only one)</span>: </h3>
			<hr />
			<ul>

				<li>
					<div style={{ display: (isDJService && eventType === "wedding") ? "block" : "none" }}>
						<label>Do you want audio services for the ceremony or the reception? <span className={styles.required}>*</span></label>



						<select onChange={onWeddingTypeChange}>
							<option>Reception and Ceremony</option>
							<option>Ceremony Only</option>
							<option>Reception Only</option>
						</select>
					</div>
				</li>

				<li>
					<div style={{ display: (isDJService && eventType === "wedding") ? "block" : "none" }}>
						<label>What time does the {weddingType} start? <span className={styles.required}>*</span></label>

						<TimePicker onTimeChange={onStartChange } />
					</div>
				</li>

				<li>
					<div style={{ display: (!isDJService || (eventType !== "wedding"))? "block" : "none" }}>
						<label>Start Time <span className={styles.required}>*</span></label>
						<TimePicker onTimeChange={ onStartChange } />
					</div>
				</li>
				<li>
					<label>End Time <span className={styles.required}>*</span></label>
					<TimePicker onTimeChange={ onEndChange } />
				</li>
				<div style={{ margin: "34px" }} />
			</ul>
			<ul className={styles.List}>
				<PackageList enabledPackages={enabledPackages} packagesData={packagesData} onPackageClick={onPackageClick} handleImageClick={handleImageClick } />
			</ul>

			{/* Video modal */}
			<Modal isOpen={isModalOpen} onClose={closeModal} videoUrl={currentVideo} />

			<ul>
				





				<li>
					

					<input className={`${styles.buttondivided} ${styles.backButton}`} type="button" value="<< Go Back" onClick={onBackClick} />
					<input className={styles.buttondivided} type="submit" value="Next >>" onClick={onNextClick} />
				</li>
			</ul>
		</div>
	);
}

export default ServicePackages;
